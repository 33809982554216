<template>
  <div>
    <el-dialog title="我的提问" :visible.sync="dialogVisible" width="700px" :before-close="handleClose" :modal="false">
      <div class="box">
        <!-- 录播 -->
        <video class="video" controls v-if="myAskQuestions &&
          myAskQuestions.status == 2 &&
          dialogVisible &&
          myAskQuestions.videoUrl.endsWith('mp4')
          " :src="myAskQuestions.videoUrl"></video>
        <jxyplayer v-else-if="myAskQuestions &&
          myAskQuestions.status == 2 &&
          myAskQuestions.videoUrl &&
          accessKeyId &&
          accessKeySecret &&
          dialogVisible
          " :access-key-id="accessKeyId" :url="myAskQuestions.videoUrl" :access-key-secret="accessKeySecret"
          :options="option" />
        <!-- 直播 -->
        <div class="live-box" v-if="liveInfo == 2">
          <div id="livePlayer"></div>
          <div id="drawPanel"></div>
        </div>
        <!-- 回放 -->
        <div class="live-box" v-if="liveInfo == 1">
          <div id="playbackPlayer"></div>
          <div id="playbackPanel"></div>
        </div>
        <div v-if="myAskQuestions && myAskQuestions.status == 4" class="testPaperBox">
          <div class="topicBox m-b-20">
            <div v-if="myAskQuestions.composeTitle" class="tag">组合题</div>
            <div class="topic">{{ myAskQuestions.composeTitle }}</div>
          </div>
          <div class="topicBox">
            <div v-if="myAskQuestions.questionSubjectVO.questionType == 0" class="tag">
              单选
            </div>
            <div v-else-if="myAskQuestions.questionSubjectVO.questionType == 1" class="tag">
              多选
            </div>
            <div v-else-if="myAskQuestions.questionSubjectVO.questionType == 2" class="tag">
              判断
            </div>
            <div v-else-if="myAskQuestions.questionSubjectVO.questionType == 3" class="tag">
              填空
            </div>
            <div v-else class="tag">主观</div>
            <div>
              <div class="topic" v-html="ImgSizeLimit(myAskQuestions.questionSubjectVO.questionTitle)
                "></div>
              <div v-for="(question, index) in questionOption" :key="question" class="radioBox">
                <span class="m-r-8">{{ options[index] }}</span>
                <el-radio disabled></el-radio>
                <span v-html="ImgSizeLimit(question)"></span>
              </div>
            </div>
          </div>
        </div>

        <!-- <div v-if="myAskQuestions && myAskQuestions.content" class="content">
          <div class="tag">问</div>
          <div class="contentBox">
            <div class="ask">{{ myAskQuestions.content }}</div>
            <div class="imgBox">
              <el-image
                class="askImg"
                v-for="item in myAskQuestions.img"
                :key="item"
                :src="item"
                :preview-src-list="myAskQuestions.img"
              ></el-image>
              <img 
                                class="askImg"
                                v-for="item in myAskQuestions.img" 
                                :key="item" 
                                :src="item"
                            >
            </div>
          </div>
        </div>
        <div v-if="myAskQuestions && myAskQuestions.replyList">
          <div
            class="answerBox"
            v-for="item in myAskQuestions.replyList"
            :key="item.id"
          >
            <div class="tag">答</div>
            <div class="contentBox">
              <div class="answer">{{ item.replyContent }}</div>
              <img v-if="item.replyImg" class="imgItem" :src="item.replyImg" alt="">
              <el-image
                v-if="item.replyImg"
                class="imgItem"
                :src="item.replyImg"
                :preview-src-list="[item.replyImg]"
              ></el-image>
            </div>
          </div>
        </div> -->
        <askQues :type="myAskQuestions.type" :is-purchase="true" v-if="dialogVisible" :status="myAskQuestions.status"
          :showId="showId" :course-id="myAskQuestions.courseId" :chapter-id="myAskQuestions.chapterId"></askQues>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import jxyplayer from "@/components/jxyPlay.vue";
import { getAccessKey } from "@/api/home";
import { Evaluation } from "@/api/scoreEvaluation";
import { getInfo } from "@/api/cookies";
import askQues from "@/components/Know/course/askQues.vue";
const evaluation = new Evaluation();
export default {
  props: ["dialogVisible"],
  components: { jxyplayer, askQues },
  data() {
    return {
      myAskQuestions: null,
      questionOption: [],
      options: ["A", "B", "C", "D", "E", "F", "G"],
      ccLiveid: "",
      ccRomid: "",
      showId: "",
      option: {
        width: "100%",
        height: "320px",
        type: "HLS",
      },
      accessKeySecret: null,
      accessKeyId: null,
      recordId: "",
      liveData: {},
      liveInfo: null, // 1 回放 2 直播
    };
  },
  created() {
    this.getAccessKey();
  },
  methods: {
    init(item) {
      this.showId = "";

      this.myAskQuestions = item;
      this.showId = item.id;
      if (
        this.myAskQuestions.questionSubjectVO &&
        this.myAskQuestions.questionSubjectVO.questionOption
      ) {
        this.questionOption = eval(
          this.myAskQuestions.questionSubjectVO.questionOption
        );
      }
      if (item.status == 1) {
        if (item.ccLiveid) {
          /* 回放 */
          this.liveInfo = 1;
          this.ccLiveid = item.ccLiveid;
          this.ccRomid = item.ccRomid;
          this.recordId = item.recordId;
          this.$nextTick(() => {
            this.startPlayback();
          });
        } else {
          /* 直播 */
          this.liveInfo = 2;
          let index = item.videoUrl.indexOf("?");
          let str = item.videoUrl.substring(index + 1);
          let strs = str.split("&");
          strs.forEach((item) => {
            this.liveData[item.split("=")[0]] = item.split("=")[1];
          });
       
          this.$nextTick(() => {
            this.startLive();
          });
        }
      }
    },
    async getAccessKey() {
      const res = await getAccessKey();
      if (res.code == 0) {
        this.accessKey = res.data;
        this.accessKeyId = res.data.accessKeyId;
        this.accessKeySecret = res.data.accessKeySecret;
      }
    },
    handleClose() {
      if (this.liveInfo == 2) {
        DWLive.destroy();
      } else if (this.liveInfo == 1) {
        $.DW.destroy();
      }
      this.ccLiveid = "";
      this.ccRomid = "";
      this.recordId = "";
      this.liveData = {};
      this.liveInfo = null;
      this.$emit("handleClose");
    },
    /* 直播 */
    startLive() {
      DWLive.init({
        userid: "41A331E332E32281", //必须参数
        roomid: this.liveData.roomid, //必须参数
        viewertoken: "123", //如果直播间设置为密码验证，必选
        pcH5Live: true,
      });
    },
    /* 回放 */
    startPlayback() {
      $.DW.config({
        userId: "41A331E332E32281",
        roomId: this.ccRomid, //直播间Id
        recordId: this.recordId, //直播回放Id
        isH5play: true,
        fastMode: true,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  height: 580px;
  overflow: auto;
  padding-bottom: 50px;

  .video {
    width: 640px;
    height: 360px;
    border-radius: 8px;
    margin-bottom: 28px;
  }

  .live-box {
    #livePlayer {
      height: 120px;
      width: 180px;
    }

    #playbackPlayer {
      height: 120px;
      width: 180px;
    }
  }

  .testPaperBox {
    border-bottom: solid 1px #eeeeee;
    margin-bottom: 24px;
    padding-bottom: 40px;

    .topicBox {
      display: flex;

      .tag {
        width: 48px;
        height: 24px;
        border-radius: 4px 4px 4px 0;
        background: linear-gradient(130deg, #ff8551 0%, #ff5e51 100%);
        text-align: center;
        line-height: 24px;
        color: #ffffff;
        font-size: 14px;
        margin-right: 12px;
      }

      .topic {
        width: 600px;
        font-size: 18px;
        color: #333333;
        margin-bottom: 33px;
      }

      .radioBox {
        display: flex;
        margin-top: 44px;
      }
    }
  }

  .content {
    display: flex;
    margin-bottom: 16px;

    .tag {
      width: 18px;
      height: 18px;
      border-radius: 3px;
      background-color: #ff5e51;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
      line-height: 18px;
      margin-right: 8px;
    }

    .contentBox {
      .imgBox {
        display: flex;

        .askImg {
          width: 40px;
          height: 40px;
          border-radius: 4px;
          margin-right: 5px;
        }
      }

      .ask {
        font-size: 14px;
        color: #666666;
        width: 602px;
        margin-bottom: 5px;
      }
    }
  }

  .answerBox {
    width: 640px;
    border-radius: 8px;
    background-color: #f5f7f9;
    padding: 16px;
    margin-bottom: 5px;
    display: flex;

    .tag {
      width: 18px;
      height: 18px;
      border-radius: 3px;
      background-color: #299f43;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
      line-height: 18px;
      margin-right: 8px;
    }

    .contentBox {
      .answer {
        width: 580px;
        margin-bottom: 5px;
      }

      .imgItem {
        width: 40px;
        height: 40px;
        border-radius: 4px;
      }
    }
  }
}

/deep/ .el-dialog__wrapper {
  z-index: 1999 !important;
}

.dis_flex {
  align-items: flex-start;
}

/deep/ .is-checked {
  margin-right: 0;
}
</style>