<template>
  <div class="box">
    <div v-for="item in askQuestionsList" :key="item.id" class="askQuestionsItem">
      <div class="leftBox">
        <div class="tagItem" :class="item.type == 3 ? 'zuoye' : ''">
          {{ item.typeName }}
        </div>
        <div class="nameOrTimeItem">
          <div class="nameItem" v-html="ImgSizeLimit(item.subsetName)" />
          <div class="timeItem">
            <span>提问时间：{{ item.addTime }}</span>
            <span class="m-l-12 m-r-12">|</span>
            <span v-if="item.replyList && item.replyList.length > 0" class="textGreen">已回答</span>
            <span v-else>未回答</span>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="btnItem red" :class="item.type == 3 ? 'grey' : ''" @click="openAskQuestions(item)">
          {{ item.type != 3 ? "我的提问" : "查看" }}
        </div>
        <!-- <div class="btnItem grey" @click="goCourse(item)">
                    {{ item.type == 1 ? '观看课程' : '查看试卷' }}
                </div> -->
        <div v-if="item.replyList.length > 0" class="btnItem grey"
          :class="item.replyList && item.replyList.length > 0 ? '' : 'noAnswer'" @click="openAskQuestions(item)">
          查看答案
        </div>
        <div v-else-if="item.type != 3" class="btnItem noAnswer">查看答案</div>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination class="pagination" :current-page="page.pageNum" :page-sizes="[10, 20, 30, 40]"
      :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total"
      @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    <!-- 我的提问 -->
    <ask-questions-dialog ref="askQuestionsDialog" :dialog-visible="dialogVisible" @handleClose="handleClose" />
  </div>
</template>

<script>
import { selectStuPcQuestionsList } from "@/api/home.js";
import { getStudentWorkList, submitWork } from "@/api/myOp";
import askQuestionsDialog from "./askQuestionsDialog.vue";
export default {
  components: {
    askQuestionsDialog,
  },
  data() {
    return {
      askQuestionsList: [],
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      dialogVisible: false,
    };
  },
  created() {
    this.selectStuPcQuestionsList();
  },
  methods: {
    selectStuPcQuestionsList() {
      const data = {
        ...this.page,
      };
      delete data.total;
      selectStuPcQuestionsList(data).then((res) => {
        res.rows.map((item) => {
          if (item.img) {
            item.img = item.img.split(",");
          }
        });
        this.page.total = res.total;
        res.rows.forEach((item) => {
          if (item.type == 1) item.typeName = "课程";
          if (item.type == 2) item.typeName = "试题";
          if (item.type == 3) item.typeName = "作业";
        });
        this.askQuestionsList = res.rows;
      });
    },
    /* 我的提问弹窗开/关 */
    openAskQuestions(item) {

      if (item.type != 3) {
        this.$refs.askQuestionsDialog.init(item);
        this.dialogVisible = true;
      } else {
        this.$router.push(
          `/seventhPage/myOperationDetails?workId=${item.courseId}`
        );
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },

    /* 点击观看课程 */
    /* goCourse(item) {
                if (item.status == 1) {
                    this.$router.push({
                        ptah: '',
                        query: {

                        }
                    })
                } else if (item.status == 2) {
                    this.$router.push({
                        ptah: '/dianbofang',
                        query: {
                            id: item.id,
                            uservideoConfig: ,
                            courseId: item.courseId,
                            classId: item.classId,
                            groupId: item.groupId,
                            typebs: item.status,
                            classProductLine: item.classProductLine
                        }
                    })
                } else {
                    this.$router.push({
                        ptah: '',
                        query: {

                        }
                    })
                }
            }, */
    /* 每页条数 */
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNum = 1;
      this.selectStuPcQuestionsList();
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.selectStuPcQuestionsList();
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background-color: #ffffff;
  width: 1220px;
  border-radius: 8px;
  padding: 40px;

  .askQuestionsItem {
    display: flex;
    border-bottom: 1px solid #eeeeee;
    padding: 40px 0;
    justify-content: space-between;
    align-items: center;

    .leftBox {
      display: flex;

      .tagItem {
        width: 40px;
        height: 20px;
        border-radius: 2px;
        background-color: #fff2e9;
        color: #ff7b1c;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
        margin-right: 12px;
      }

      .zuoye {
        color: #666666;
        background-color: #f2f2f2 !important;
      }

      .nameOrTimeItem {
        .nameItem {
          color: #333333;
          margin-bottom: 19px;
          width: 748px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .timeItem {
          color: #999999;
          font-size: 14px;

          .textGreen {
            color: #3b9d4b;
          }
        }
      }
    }

    .rightBox {
      display: flex;
      justify-content: flex-end;

      .btnItem {
        width: 88px;
        height: 30px;
        border-radius: 4px;
        text-align: center;
        line-height: 30px;
        border: 1px solid;
        font-size: 14px;
        margin-right: 16px;
        cursor: pointer;
      }

      .red {
        border-color: #ff5e51;
        color: #ff5e51;
      }

      .grey {
        border-color: #dddddd;
        color: #666666;
      }

      .noAnswer {
        border-color: #cccccc !important;
        color: #cccccc !important;
      }
    }
  }
}
</style>
